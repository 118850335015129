*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.background {
  height: 100%;
  width: 100%;
  padding: none;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: -1;
}
.fade-in{
  transition: fade_in 500ms ease-in-out;
}

@keyframes fade_in{
  from {opacity: 0;}
  to {opacity: 1;}
}