*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.hero{
    margin: 0px 110px;
    margin-top: 220px;
}
.hero_text{
    color: #fff;
    font-size: 35px;
    font-weight: 400;
}
.hero_explore{
    display: flex;
    align-items: center;
    gap: 50px;
    background-color: white;
    width: fit-content;
    margin-top: 40px;
    padding: 5px 10px;
    margin-left: 30px;
    border-radius: 50px;
    cursor: pointer;
    
}
.hero_explore p{
    color: #292929;
    font-size: 18px;
    font-weight: 400;
}
.hero_explore img{
    width: 40px;
}
.hero-dot-play{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    /* margin-top: 50px; */
}
.hero-dots{
    display: flex;
    align-items: center;
    gap: 23px;
    list-style: none; 
}
.hero-dot{
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 8px;
    cursor: pointer;
}
.orange{
background: orangered;
}
.hero-play{
    display: flex;
    align-items: center;
    gap: 30px;
}
.hero-play p{
    color: #fff; 
}
.hero-play img{
    width:50px;
}