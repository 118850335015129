.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 110px;
  color: white;
}
.nav-logo {
  font-family: Outfit;
  font-size: 38px;
  font-weight: 500;
}
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 18px;
  font-weight: 500px;
  gap: 70px;
}
.nav-contact{
    border-radius: 50px;
    padding: 8px 32px;
    background: #fff;
    color:#262626;
}